<template>
  <v-container fluid>
    <main-layout />
    <div class="mx-4">
      <v-row justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="pa-0">
          <h2 class="ml-2 mt-8 font-weight-black">
            {{ $t("reset_password.title") }}
          </h2>
          <h4 class="ml-3 mb-8 mt-2 font-weight-light">
            {{ $t("reset_password.reset_text") }}
          </h4>
        </v-col>
      </v-row>
      <v-row justify-sm="center">
        <v-col cols="12" sm="6" md="4" class="pa-0">
          <v-text-field
            label="Email"
            color="primary"
            :rules="emailRules"
            validate-on-blur
            outlined
            v-model="emailValue"
            @keypress.enter="recoverPassword(emailValue)"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-row class="mx-4 my-8" justify="center" justify-md="center">
      <v-col cols="12" sm="4" md="2" class="pa-0 text-right">
        <div>
          <v-btn
            color="secondary"
            block
            large
            rounded
            @click="recoverPassword(emailValue)"
            class="text-h6 font-weight-light"
            style="text-transform: none"
          >
            {{ $t("reset_password.send") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-footer :absolute="true" color="transparent" class="primary--text">
      <v-row class="mb-10">
        <v-col class="text-center">
          <span class="text-subtitle-1"
            >{{ $t("reset_password.remember_password") }}
            <a class="font-weight-bold" @click="redirectLogin">{{
              $t("reset_password.access")
            }}</a></span
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
import MainLayout from "@/components/layouts/Main.vue";
import AuthService from "@/services/AuthService.js";
export default {
  components: {
    MainLayout,
  },
  data: () => ({
    emailValue: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
  }),
  methods: {
    redirectLogin() {
      this.$router.push({ name: "login" });
    },

    async recoverPassword(email) {
      try {
        await AuthService.passwordReset({ email: email });
        this.$router.push({ name: "email-send" });
      } catch (error) {
        this.$notify({
          title: this.$i18n.tc("reset_password.error_send", 1),
          text: this.$i18n.tc("reset_password.error_send", 2),
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #718f94;
}
</style>
